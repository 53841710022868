import React, { useState } from "react";

const ReaderDb = () => {

      const [selectedImage, setSelectedImage] = useState(null);
    
      // Инициализация базы данных IndexedDB
      const openDatabase = () => {
        return new Promise((resolve, reject) => {
          const request = indexedDB.open("ImageDatabase", 1);
    
          request.onupgradeneeded = (event) => {
            const db = event.target.result;
            if (!db.objectStoreNames.contains("images")) {
              db.createObjectStore("images", { keyPath: "id" });
            }
          };
    
          request.onsuccess = (event) => resolve(event.target.result);
          request.onerror = (event) => reject(event.target.error);
        });
      };
    
      // Сохранение изображения в IndexedDB
      const saveImageToDB = async (image) => {
        const reader = new FileReader();
      
        reader.onload = async () => {
          const db = await openDatabase();
          const transaction = db.transaction("images", "readwrite");
          const store = transaction.objectStore("images");
      
          store.put({ id: "selectedImage", data: reader.result });
      
          transaction.oncomplete = () => {
            console.log("Image saved to IndexedDB");
          };
          transaction.onerror = (event) => {
            console.error("Error saving image:", event.target.error);
          };
        };
      
        reader.readAsDataURL(image);
      };
      
    
      // Загрузка изображения из IndexedDB
      const loadImageFromDB = async () => {
        const db = await openDatabase();
        const transaction = db.transaction("images", "readonly");
        const store = transaction.objectStore("images");
    
        const request = store.get("selectedImage");
        request.onsuccess = (event) => {
          const result = event.target.result;
          if (result) {
            setSelectedImage(result.data);
          } else {
            console.log("No image found in IndexedDB");
          }
        };
      };
    
      return (
        <div style={{ padding: "20px", textAlign: "center" }}>
          <h1>React + IndexedDB: Save and Load Image</h1>
    
          {/* Поле выбора файла */}
          <input
            type="file"
            accept="image/*"
            onChange={(e) => {
              const file = e.target.files[0];
              if (file) saveImageToDB(file);
            }}
          />
    
          <div style={{ margin: "20px" }}>
            <button onClick={loadImageFromDB}>Открыть</button>
          </div>
    
          {/* Отображение загруженного изображения */}
          {selectedImage && (
            <div>
              <h2>Загруженное изображение:</h2>
              <img
                src={selectedImage}
                alt="From IndexedDB"
                style={{ maxWidth: "100%", maxHeight: "300px" }}
              />
            </div>
          )}
        </div>
      );
    };
        

export {ReaderDb}