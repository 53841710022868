const payment = async () => {
    const tg = window.Telegram.WebApp;
  
    try {
      // Параметры инвойса
      const invoiceData = {
        title: "Поблагодарить сервис",
        description: "Донат разработчикам",
        payload: JSON.stringify({ action: 'donate', amount: 10 }), // Платежная информация
        provider_token: "", // Оставьте пустым для оплаты в Telegram Stars
        currency: "XTR", // Валюта для Telegram Stars
        prices: [{ label: "10 Stars", amount: 10 }], // Сумма в минимальных единицах (звездах)
      };
  
      const TELEGRAM_TOKEN = "7626723930:AAHVgy6R82P_KVx4sO0dkNaciSiFg6PxwPU"; // Вставьте ваш токен
      const TELEGRAM_API_URL = `https://api.telegram.org/bot${TELEGRAM_TOKEN}/createInvoiceLink`;
  
      // Отправка запроса к Telegram Bot API
      const response = await fetch(TELEGRAM_API_URL, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(invoiceData),
      });
  
      const data = await response.json();
  
      if (!data.ok) {
        throw new Error(data.description);
      }
  
      // Получение ссылки на инвойс
      const invoiceLink = data.result;
  
      // Открытие инвойса внутри приложения
      tg.openInvoice(invoiceLink, (status) => {
        if (status === 'paid') {
          alert("Спасибо за Вашу поддержку!");
        } else {
          alert("Платеж не прошел, повторите попытку позднее");
        }
      });
    } catch (error) {
      console.error("Error while handling donation:", error);
      alert("Произошла ошибка, попробуйте позже");
    }
  };
  
  export default payment;
  

