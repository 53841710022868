
import './App.css';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import {collection, getFirestore, addDoc, getDocs, query, where} from 'firebase/firestore'; // Firebase Modular SDK импорты
import { initializeApp } from 'firebase/app';
import React, {useState, useEffect } from 'react';

import { AdRentMoto } from './js/AdRentMoto';
import {NotFound} from './js/notFound'
import { HomePageNew } from './js/HomePageNew';
import { Profile } from './js/Profile';
import { ProfilePage } from './js/ProfilePage';
import { CreateRentBike } from './js/CreateRentBike';
import { Search } from './js/Search';
import { Reader } from './js/Reader';
import { AllAdGenre } from './js/AllAdGenre';
// import { ListSaveAds } from './js/ListSaveAds';
import { RedAdd } from './js/RedAdd';
import { ListSaveProfiles } from './js/ListSaveProfiles';

import firebaseConfig from './js/firebaseConfig';
import { LoadingScreen } from './js/LoadingScreen';
import { Tester } from "./js/Tester"

import { ReaderDb } from './js/ReaderDb';

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

function App() {

  //Сделано для того чтобы успело все програузится, в противном случае отображается сначала нотФаунд
  const [isLoading, setIsLoading] = useState(false);

  useEffect(()=>{
    const delay = setTimeout(() => {
      setIsLoading(true);
    }, 3000);
  
    return () => {
      clearTimeout(delay);
    };
  
  },[])

  //Данные пользователя телеграмм
  const [userData, setUserData] = useState(null)

  useEffect(() => {
    if (window.Telegram) {
      const tg = window.Telegram.WebApp;
      const gl = window.Telegram
      tg.ready();
      tg.setHeaderColor('#cce3fd')
      tg.setBackgroundColor('#ffffff')
      tg.disableVerticalSwipes()
      tg.lockOrientation()
      // tg.exitFullscreen()
      console.log('Telegram WebApp initialized:', tg);
      console.log('Глобальный ТГ:', gl);
      // console.log('Проверяем, возможно данные чата:', tg.initData);
      // console.log("Данные пользователя", tg.initDataUnsafe.user)
      // console.log(tg.initDataUnsafe.user)
      setUserData(tg.initDataUnsafe.user)
      console.log("ДАННЫЕ ПОЛЬЗОВАТЕЛЯ", tg.initDataUnsafe.user)
      // tg.SettingsButton.show();
      verifyUser(window.Telegram.WebApp.initData)
      tg.onEvent('settingsButtonClicked', () => {
        console.log('Settings button was clicked');
      });
      if (userData) {
      }
    } 
  }, []);


  const [allData, setAllData] = useState(null)

  useEffect(()=>{  
    // Функция для получения данных пользователя по его id
    const getAllData = async () => {
      try {
        const usersCollectionRef = collection(db, 'selbo');
        const querySnapshot = await getDocs(usersCollectionRef);

        if (querySnapshot.empty) {
          console.log("Нет данных")
          return null;
        }
        const getAllData = [];
        querySnapshot.forEach((doc) => {
            getAllData.push({ id: doc.id, ...doc.data() });
        });
        console.log("Извлеченные данные книги", getAllData)
        setAllData(getAllData)        
        return getAllData;
      } catch (error) {
        console.error('Ошибка при получении данных пользователя:', error);
        return null;
      }
    };

    //Функция отображения объявлений
    const allAdd = () => {
        getAllData().then((getAllData) => {
      if (getAllData) {
        setAllData(getAllData)
      }
    });
    }
    allAdd()
    },[])

    useEffect(() => {
      const saveUser = async () => {
        try {
          // Создаем запрос для проверки существования документа с указанным userData.id
          const q = query(
            collection(db, "selbousers"),
            where("userData.id", "==", userData.id) // Проверяем поле id в userData
          );
          
          const querySnapshot = await getDocs(q);
    
          if (querySnapshot.empty) {
            // Если документов не найдено, добавляем нового пользователя
            await addDoc(collection(db, "selbousers"), { userData });
            console.log("Пользователь успешно добавлен.");
          } else {
            console.log("Пользователь уже существует.");
          }
        } catch (error) {
          console.error("Ошибка при добавлении пользователя:", error);
        }
      };
    
      if (userData && userData.id) {
        saveUser();
      }
    }, [userData]);

    //Верификация телеграм
    async function verifyUser(initData) {
      try {
          const response = await fetch('https://tranquil-citadel-64673-5b2d65271b20.herokuapp.com/verify-telegram', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({ initData }),
          });
  
          const result = await response.json();
  
          if (result.success) {
              console.log('User verified successfully');
          } else {
              console.error('User verification failed:', result.message);
          }
      } catch (error) {
          console.error('Error during verification:', error);
      }
  }
  
  
  return (

    <Router >
      <div className="cont">
      <Routes>
      {/* <Route path="/" element={
        <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
      <LoadingScreen data={allData}/>s
      <p style={{magin:"0px", marginTop:"10px", color:"black", fontSize:"18px"}}>Загрузка книги...</p>
      </div>
      } /> */}
      <Route path="/tester" element={<Tester/>} />
      <Route path="/" element={<HomePageNew data={allData}/>} />
      <Route path="profile" element={<Profile userData={userData} allData={allData}/>} />
      <Route path="addrentbike" element={<CreateRentBike userData={userData}/>} />
      <Route path="profilepage" element={<ProfilePage allData={allData}/>} />
      <Route path="search" element={<Search allData={allData}/>} />
      <Route path="genre" element={<AllAdGenre allData={allData}/>} />

      <Route path="readerdb" element={<ReaderDb />} />

      {/* <Route path="saveads" element={<ListSaveAds allData={allData}/>} /> */}
      <Route path="savepropfiles" element={<ListSaveProfiles allData={allData}/>} />
        {allData && allData.map((ad, ind)=>
        <Route key={ind} path={`/ad${ad.formItems.art}`} element= {<AdRentMoto ad={ad.formItems} userData={userData}/>}/>
        )}
        {allData && allData.map((ad, ind)=>
        <Route key={ind} path={`/redad${ad.formItems.art}`} element= {<RedAdd ad={ad.formItems} userData={userData} id={ad.id}/>}/>
        )}
        {allData && allData.map((ad, ind)=>
        <Route key={ind} path={`/read${ad.formItems.art}`} element= {<Reader ad={ad.formItems} userData={userData}/>}/>)}
        {isLoading ? <Route path="*" element={<NotFound />} />: <Route path="*" element={<LoadingScreen/>}/>}
      </Routes>
      </div>
    </Router>

  );
}

export default App;
