
import React, {useEffect, useState } from 'react';
import { useLocation } from 'react-router';

// import Category from "./Category";
import '../css/HomeMarket.css'
import lupa from '../image/lupa.png'
import user from '../image/user.png'
import bookRead from '../image/contRead3.png'
import plus from '../image/donate.png'
import { useNavigate } from 'react-router';
import ListCategories from './ListCategories'
import AllAd from './AllAd';
import getBookLocal from './getBookLocal';
import payment from './Payment';



const HomePageNew = props => {
  const navigate = useNavigate()

  const allData = props.data
  const [filterData, setFilterData] = useState(null)

  const [focus1, setFocus1] = useState(false)
  const [focus2, setFocus2] = useState(true)
  const [focus3, setFocus3] = useState(false)

    //Проверка параметров адресной строки
    const location = useLocation()
    useEffect(() => {
      const searchParams = new URLSearchParams(location.search);
      for (let [key, value] of searchParams.entries()) {
        const isOpen = true
        navigate(`/${value}`, { state: { isOpen } });
      }

    }, [location.search]);

  //Когнда в поиске ничего не найдено
  const [empty, setEmpty] = useState(null)

  useEffect(() => {  
    // Сортировка и фильтрация данных
    const handleSortAndFilter = () => {
      if (allData) {
        // Копирование данных и фильтрация
        const filteredData = allData.filter(item => item.formItems.show === true && item.formItems.clientNotShow === false);
  
        // Сортировка отфильтрованных данных
        const sortedData = filteredData.sort((a, b) => b.formItems.gold - a.formItems.gold);
  
        // Запись отсортированных данных в state
        setFilterData(sortedData);
      }
    };
  
    handleSortAndFilter();
    // eslint-disable-next-line
  }, [allData]);
  

  useEffect(() => {
    window.scrollTo(0, -80);
  },[])


const getSearch = (search) => {
  if (search!=='') {
    const filtered = (allData.filter(item => 
      Object.values(item.formItems).some((value) =>
      value.toString().toLowerCase().includes(search.toLowerCase())
      )
      )
    )
      setFilterData(filtered)
    if (filtered.length === 0){setEmpty('По Вашему запросу ничего не найдено')}
        else {setEmpty(null)}
    }
  else {
    setEmpty(null)
    setFilterData(allData)
  }
}
const btn1 = () => {
  setFocus1(true)
  setFocus2(false)
  setFocus3(false)
  // navigate('/profile', { state: true })
  navigate('/addrentbike')
}
const btn2 = () => {
  setFocus2(true)
  setFocus1(false)
  setFocus3(false)
}
const btn3 = () => {
  setFocus3(true)
  setFocus1(false)
  setFocus2(false)
}
const goSearch = ()=>{
  navigate('/search')
}
const goProfile = ()=>{
  navigate('/profile')
}
const goToRead = () => {
  const key = 'nameBook'
  getBookLocal(key, (error, data) => {
      if (error) {
        console.error("Ошибка:", error);
      } else if (data) {
      const linkBook = data.link
      const art = data.art
      const page = data.page
      const part = data.part
      navigate(`/read${data.art}`, { state: { linkBook, art, page, part} })
        console.log("Полученные данные:", data);
      } else {
        console.log("Данные не найдены");
      }
    });
}
  const tryDonate = () => {
    payment()
  }

  const goToTest = () => {
    navigate("/readerdb")
  }

    return (
      <div> 
        <div className="contHMarket">
          <p className="hMarket" onClick={goToTest}>TG Selbo</p>
          <div>
            <img src={bookRead} alt="u" className="imgUser" onClick={goToRead}/>
            <img src={user} alt="u" className="imgUser" onClick={goProfile} style={{marginLeft:"8px"}}/>
            <img src={lupa} alt="u" style={{marginLeft:"8px"}} className="imgUser" onClick={goSearch}/>
            <img src={plus} alt="u" style={{marginLeft:"8px"}} className="imgUser" onClick={tryDonate}/>
          </div>
        </div>
        <div className="threeBtn">
          <p className={focus1 ?"textBtnFocus":"textBtn"} onClick={btn1}>Опубликовать книгу</p>
          <p className={focus2 ?"textBtnFocus":"textBtn"} onClick={btn2}>Для вас</p>
          <p className={focus3 ?"textBtnFocus":"textBtn"} onClick={btn3}>Жанры</p>
        </div>
        {/* <Category getSearch={getSearch}/> */}
        {focus3 ?
        <p style={{fontWeight:"bold", fontSize:"17px", marginLeft:"3%", width:"94%", borderTop:"1px solid rgb(194, 194, 194)", marginRight:"3%", paddingTop:"10px"}}>Популярные категории</p>:
        <div className="underHead">
          <h3 style={{margin:"0"}}>Выбор дня:</h3>
          {/* <p className="textUnderHome"><img style={{width:"24px", height:"24px", marginRight:"5px"}} src={location1} alt="|"/>Таиланд, Пхукет</p> */}
        </div>
        }
        <div>
        {focus3 ?
        <div style={{paddingLeft:"3%", paddingRight:"3%"}}>
        <ListCategories filterData={filterData}/>
        </div> :
        <AllAd filterData={filterData} />
        }
        </div>
      </div>
    )
}

export {HomePageNew}