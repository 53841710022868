import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { initializeApp } from 'firebase/app';
import {collection, addDoc, getFirestore} from 'firebase/firestore'; 
import { useState, useRef } from "react";
import '../css/NewFormAdd.css'
import ButtonFormAdd from "./buttonFormAdd";
import data from './fields.json'
import firebaseConfig from './firebaseConfig';
import { extractCoverImage } from "./extractCover";
// import { parseFb2 } from "./parseFb2";
import { extractInformation } from "./extractInformation";
import { useNavigate } from "react-router";


import addPhoto from '../image/addPhoto.png'
import { Navigate } from "react-router";


const FormAddRentTran = props => {

    //Предупреждение видимость обязательных полей
    const [visWar, setVisWar] = useState(false)

    //Предупреждение нужно добавить хотя-бы одну фотографию
    const [visPhoto, setVisPhoto] = useState(false)

    //Файл книги
    // const [file, setFile] = useState(null);
    const [coverImage, setCoverImage] = useState(null);
      
    const app = initializeApp(firebaseConfig);
    const storage = getStorage(app);
    const db = getFirestore(app);

    //Объект который будем менять с помощью инпутов. По умолчанию подтягиваем поля из json в квадратных скобках указываем категорию объекта переданную в пропсах
    const [formItems, setFormItems] = useState(data.books.inputs)

    const [download, setDownload] = useState(false)

    //Точки в конце "Загружаем изображения"
    const [dots, setDots] = useState(0);

    //Неактивность кнопки
    const [visButton, setVisButton] = useState(false)

    const navigate = useNavigate()


    const scrollDown = () => {
      window.scrollTo({ top: document.documentElement.scrollHeight, behavior: 'smooth' });
    }

    //Функция изменения значения
    const handleChange = (event) => {
      //Нужно подумать с ИД !!!!!!!!!!!!!!!!!!!!!
      setFormItems((prevState) => ({
        ...prevState,
        userId: "124kjkhjk4"
      }))
      setFormItems((prevState) => ({
        ...prevState,
        firstName: props.userData.first_name
        // firstName: "Leonid"
      }))
      setFormItems((prevState) => ({
        ...prevState,
        lastName: props.userData.last_name
        // lastName: "Sushkov"
      }))
      setFormItems((prevState) => ({
        ...prevState,
        telegram: props.userData.username
        // telegram: "Leonid2323"
      }))
      setFormItems((prevState) => ({
        ...prevState,
        whatsapp: props.userData.id
        // whatsapp: "1112ret"
      }))
      setFormItems((prevState) => ({
        ...prevState,
        dateAd: Date.now()
      }))
      setFormItems((prevState) => ({
        ...prevState,
        gold: 1
      }))
      setFormItems((prevState) => ({
        ...prevState,
        show: true
      }))
      setFormItems((prevState) => ({
        ...prevState,
        clientNotShow: false
      }))
      setFormItems((prevState) => ({
        ...prevState,
        art: uniquImg
      }))
      const { name, value } = event.target;
      setFormItems((prevState) => ({
        ...prevState,
        [name]: value
      }));
    };
  
  //Для уникальности изображения и артикул товара
  const uniquImg = String(Date.now()).slice(-5);
  
  //Изменяем кнопку input
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
    // setTextDown(null)
  };

  //Функция очистки всех полей в объекте создания документа
  const handleClear = () => {
    const clearedUser = Object.keys(formItems).reduce((acc, key) => {
      acc[key] = '';
      return acc;
    }, {});
    setFormItems(clearedUser);
  };

//Проверка инпутов и вывод предупреждения
const [showWarning, setShowWarning] = useState({
  nameBook:false,
  author:false,
  genre:false,
  discription:false,
})

const arrFields = ["nameBook","author","genre","discription"]

const getTrim = () => {
  arrFields.map((field) => {
    if (!formItems[field] || formItems[field].trim() === '') {
      setShowWarning((prevWarnings) => ({
        ...prevWarnings,
        [field]: true
      }));
    } else {
      setShowWarning((prevWarnings) => ({
        ...prevWarnings,
        [field]: false
      }));
    }
    return null;
  });
};

//Функция создания Объявления
  const handleSave = async () => {
    getTrim()
    if (
    formItems.nameBook.trim() === '' || 
    formItems.author.trim() === '' || 
    formItems.genre.trim() === '' ||
    formItems.discription.trim() === ''
    ){
      setVisWar(true)
    } else if (formItems.imageUrl.length<1) {
      setVisPhoto(true)
      setVisWar(false)
    }
    else {
    setVisButton(true)
    try {
      // Создаем новый документ в коллекции "users" с данными пользователя
// eslint-disable-next-line
      const docRef = await addDoc(collection(db, 'selbo'), {
        formItems,
        countShow:0
      });
      // Очищаем поля ввода после сохранения данных
      //ОТПРАВКА В ТЕЛЕГРАММ ИНФОРМАЦИИ ЧТО ПОСТУПИЛО НОВОЕ ОБЪЯВЛЕНИЕ, ПОЗЖЕ ИЗМЕНЮ ЧАТ И ОБЯЗАТЕЛЬНО ДОБАВЛЮ!!!!!!!!
      // sendMassage()
      // sendMassagePhoto(formItems.imageUrl[0])
      handleClear()
      navigate('/'); // Для обновления URL в истории
      window.location.href = '/'; // Полная перезагрузка страницы
    } catch (error) {
      setVisButton(false)
      console.error('Ошибка при добавлении документа:', error);
    }
  }
  };
//Записываем текст книги в переменную
// const parseRight = (file)=> {
//   setFormItems((prevState) => ({
//     ...prevState,
//     textBook: parseFb2(file)
//   }))
  
// }
  
 // Извлечение изображения из файла FB2
const readFB2File = (file, callback) => {
  const reader = new FileReader();
  reader.onload = (event) => {
    const fb2Content = event.target.result;
    // parseRight(fb2Content)
    const coverImg = extractCoverImage(fb2Content); // Используем функцию для извлечения изображения
    if (coverImg) {
      setCoverImage(coverImg); // Устанавливаем изображение в состояние
      callback(coverImg); // Передаем изображение в колбэк для дальнейшей загрузки
    }
    const objInform = extractInformation(fb2Content)
    if (objInform) {
      setFormItems((prevState) => ({
        ...prevState,
        userId: "124kjkhjk4"
      }))
      setFormItems((prevState) => ({
        ...prevState,
        recense: ""
      }))
      setFormItems((prevState) => ({
        ...prevState,
        firstName: props.userData.first_name
        // firstName: "Leonid"
      }))
      setFormItems((prevState) => ({
        ...prevState,
        lastName: props.userData.last_name
        // lastName: "Sushkov"
      }))
      setFormItems((prevState) => ({
        ...prevState,
        telegram: props.userData.username
        // telegram: "Leonid2323"
      }))
      setFormItems((prevState) => ({
        ...prevState,
        urlImg: props.userData.photo_url
        // telegram: "Leonid2323"
      }))
      setFormItems((prevState) => ({
        ...prevState,
        whatsapp: props.userData.id
        // whatsapp: "1112ret"
      }))
      setFormItems((prevState) => ({
        ...prevState,
        dateAd: Date.now()
      }))
      setFormItems((prevState) => ({
        ...prevState,
        gold: 1
      }))
      setFormItems((prevState) => ({
        ...prevState,
        show: true
      }))
      setFormItems((prevState) => ({
        ...prevState,
        clientNotShow: false
      }))
      setFormItems((prevState) => ({
        ...prevState,
        art: uniquImg
      }))
      setFormItems((prevState) => ({
        ...prevState,
        nameBook: objInform.title
      }))
      setFormItems((prevState) => ({
        ...prevState,
        author: objInform.author
      }))
      setFormItems((prevState) => ({
        ...prevState,
        discription: objInform.description
      }))
      setFormItems((prevState) => ({
        ...prevState,
        genre: objInform.genre
      }))
    }
  };
  reader.readAsText(file);
};

// Загрузка книги и изображения обложки
const handleImageUpload = async (event) => {
  setDownload(true);
  
  const intervalId = setInterval(() => {
    setDots((prevDots) => (prevDots < 3 ? prevDots + 1 : 0));
  }, 300);

  setTimeout(() => {
    clearInterval(intervalId);
  }, 15000);

  const files = event.target.files;
  const file = files[0]; // Используем первый файл для FB2

  if (file) {
    // Извлекаем изображение обложки, а затем загружаем файл и обложку
    readFB2File(file, async (coverImg) => {
      try {
        const storageRefs = [];
        const getImageUrls = [];

        // Загружаем файл FB2 в Firebase Storage
        const storageRef = ref(storage, `books/${file.name}`);
        await uploadBytes(storageRef, file);

        // Получаем URL загруженного файла книги
        const fileUrl = await getDownloadURL(storageRef);
        getImageUrls.push(fileUrl);

        // Преобразуем изображение coverImg в Blob для загрузки в Firebase
        const coverBlob = await (await fetch(coverImg)).blob();

        // Создаем уникальное имя для изображения обложки
        const coverRef = ref(storage, `books/covers/${Date.now()}_cover.png`);

        // Загружаем изображение обложки в Firebase Storage
        await uploadBytes(coverRef, coverBlob);

        // Получаем URL для изображения обложки
        const coverUrl = await getDownloadURL(coverRef);
        getImageUrls.push(coverUrl);

        // Сохраняем ссылки на файлы в базе данных
        setFormItems((prevState) => ({
          ...prevState,
          imageUrl: [...prevState.imageUrl, ...getImageUrls],
        }));

        setDownload(false);
      } catch (error) {
        console.error('Ошибка при загрузке изображений и книги:', error);
        setDownload(false);
      }
    });
  }
};

    const removeImg = (ind) => {
      setCoverImage(null)
      setFormItems((prevState) => ({
        ...prevState,
        imageUrl: []
      }));
    }
    //добавляем марждин в депозит
    const [getMargin, setGetMargin] = useState(false)
    

    return (
      <div className='contFormAdNew'>
        <div className="contAllImg">

         {formItems.imageUrl.length>0 && 
         <div  className="divImgAd">
           <img  src={coverImage} alt={1} className='imgMapAd'/> 
           <div style={{position:"absolute", top:"7px", left:"7px"}}>
              <ButtonFormAdd  onClick={() => removeImg(0)} ind={0}></ButtonFormAdd>
           </div>
          </div> }

          {!coverImage && <div className='formBtnAdNew' onClick={handleButtonClick}>
            <img src={addPhoto} alt="!"/>
            <p>Добавить книгу</p>
          </div>}
          </div>
          <input  style={{ display: 'none' }} ref={fileInputRef} type="file"  accept=".fb2" onChange={handleImageUpload} placeholder='Файлы' />
         {download && <p>Загружаем книгу{'.'.repeat(dots)}</p>}
         {visPhoto ? <p className="pWarning" style={{marginTop:"0px"}} >Необходимо добавить книгу</p>:
          !coverImage ? <p style={{color:"grey", fontSize:"12px"}}>Загрузите книгу в формате fb2. Загрузить можно только одну книгу.</p>: <p style={{color:"grey", fontSize:"12px"}}>Книга в формате fb2 успешно загружена.</p>
         }
         <div style={{position:"relative"}}>
         {formItems.nameBook.trim() !== '' && <p style={{position:"absolute", fontSize:"12px", color:"gray", left:"10px", top:"-5px"}}>Название книги</p>}
          <input 
            className="inputAddNew" 
            placeholder="Название книги"
            maxLength={50}
            name="nameBook"
            type="text"
            value={formItems.nameBook}
            onChange={handleChange}
            style={formItems.nameBook.trim() !== '' ? {paddingTop:"15px", fontSize:"14px"}:{}}
          />
          </div>
          {showWarning.author && <p className="pWarning">Поле должно быть заполено</p>}
          <div style={{position:"relative"}}>
         {formItems.author.trim() !== '' && <p style={{position:"absolute", fontSize:"12px", color:"gray", left:"10px", top:"-5px"}}>Автор</p>}
          <input 
            className="inputAddNew" 
            placeholder="Автор книги"
            maxLength={30}
            name="author"
            type="text"
            value={formItems.author}
            onChange={handleChange}
            style={formItems.author.trim() !== '' ? {paddingTop:"15px", fontSize:"14px"}:{}}
          />
          </div>
          {showWarning.model && <p className="pWarning">Поле должно быть заполено</p>}
          <div style={{position:"relative"}}>
         {formItems.genre.trim() !== '' && <p style={{position:"absolute", fontSize:"12px", color:"gray", left:"10px", top:"-5px"}}>Жанр</p>}
         <input
            className="inputAddNew"
            placeholder="Жанр"
            name="genre"
            onWheel={(e) => e.target.blur()}
            value={formItems.genre}
            onChange={handleChange}
            style={formItems.genre.trim() !== '' ? { paddingTop: "15px", fontSize: "14px" } : {}}
          />

          </div>
          {showWarning.genre && <p className="pWarning">Необходимо выбрать жанр</p>}
          <div style={{position:"relative"}}>
         {formItems.discription.trim() !== '' && <p style={{position:"absolute", fontSize:"12px", color:"gray", left:"10px", top:"-5px"}}>Описание</p>}
          <textarea
            placeholder="Описание"
            className="areaAddNew" 
            name="discription"
            maxLength={3500}
            type='text'
            value={formItems.discription}
            // style={{height:height}}
            onChange={(e)=> {handleChange(e)}}
            onFocus={()=> {scrollDown(); setGetMargin(true)}}
            onBlur={()=> setGetMargin(false)}
            style={formItems.discription.trim() !== '' ? {paddingTop:"20px", fontSize:"14px"}:{}}
          />
          </div>
          {showWarning.discription && <p className="pWarning">Поле должно быть заполено</p>}
          <p style={{color:"grey", fontSize:"12px", margin:"0", marginTop:"-10px"}}>Краткое описание книги. Можете оставить существующие описание, отредактировать его, или предложить свое.</p>
            {/* <p style={{color:"grey", fontSize:"12px", margin:"0", marginTop:"-10px"}}>Кратко опишите сюжет книги, Ваши мысли об авторе, о самой книге, эмоциии которая вызывает книга. Укажите все что на Ваш взгляд может быть полезно потенциальному читателю.</p> */}
          {visWar && <p className="pWarning" style={{marginTop:"15px", fontSize:"14px"}}>Проверьте правильность заполнение всех обязательных полей!</p>}

          <button className="btnAddSave" onClick={handleSave} disabled={visButton} style={getMargin ? {marginBottom:"200px"}:{}}>Опубликовать книгу</button>
      
      </div>
    );
  }



export default FormAddRentTran