import React, { useState, useRef, useEffect } from 'react';
import '../css/Tester.css';

const Tester = () => {
  const [bookContent, setBookContent] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [bookTitle, setBookTitle] = useState('');
  const [coverImage, setCoverImage] = useState(null);
  const contentRef = useRef(null);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.name.endsWith('.fb2')) {
      const reader = new FileReader();
      reader.onload = () => parseFB2(reader.result);
      reader.readAsText(file);
    } else {
      alert('Please upload a valid .fb2 file');
    }
  };

  const parseFB2 = (content) => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(content, 'application/xml');
    const chapters = Array.from(xmlDoc.querySelectorAll('title, body > section')); // Includes titles and sections

    const pages = [];
    let currentPageText = '';
    const screenWidth = window.innerWidth * 0.9;
    const screenHeight = window.innerHeight * 0.95;
    const lineHeight = 24; // Approximate line height for text rendering

    chapters.forEach((chapter) => {
      if (chapter.tagName === 'title') {
        if (currentPageText) {
          pages.push(currentPageText.trim());
          currentPageText = '';
        }
        pages.push(chapter.textContent.trim()); // Add chapter titles as individual pages
      } else {
        const textBlocks = chapter.querySelectorAll('p');
        textBlocks.forEach((block) => {
          const paragraph = block.textContent.trim();
          const words = paragraph.split(' ');
          words.forEach((word) => {
            const testText = currentPageText + word + ' ';
            const textMetrics = measureText(testText, screenWidth, lineHeight);

            if (textMetrics.height > screenHeight) {
              pages.push(currentPageText.trim());
              currentPageText = word + ' ';
            } else {
              currentPageText = testText;
            }
          });
          currentPageText += '\n'; // Ensure each <p> starts on a new line
        });
      }
    });

    if (currentPageText) {
      pages.push(currentPageText.trim());
    }

    const cover = xmlDoc.querySelector('binary');
    if (cover) {
      const coverData = `data:image/jpeg;base64,${cover.textContent}`;
      setCoverImage(coverData);
    }

    setBookTitle(xmlDoc.querySelector('book-title')?.textContent || 'Untitled Book');
    setBookContent(pages);
  };

  const measureText = (text, maxWidth, lineHeight) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = '16px Arial';
    const words = text.split(' ');
    let line = '';
    let height = lineHeight;

    words.forEach((word) => {
      const testLine = line + word + ' ';
      const metrics = context.measureText(testLine);
      const testWidth = metrics.width;
      if (testWidth > maxWidth && line !== '') {
        height += lineHeight;
        line = word + ' ';
      } else {
        line = testLine;
      }
    });

    return { height };
  };

  const handleSwipe = (direction) => {
    if (direction === 'left' && currentPage < bookContent.length - 1) {
      setCurrentPage(currentPage + 1);
    } else if (direction === 'right' && currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleTouchStart = (event) => {
    contentRef.current.startX = event.touches[0].clientX;
  };

  const handleTouchEnd = (event) => {
    const endX = event.changedTouches[0].clientX;
    if (contentRef.current.startX - endX > 50) handleSwipe('left');
    if (endX - contentRef.current.startX > 50) handleSwipe('right');
  };

  return (
    <div className="app">
      {/* <h1>{bookTitle}</h1> */}
      {!bookContent.length && (
        <div className="file-upload">
          <input type="file" accept=".fb2" onChange={handleFileUpload} />
        </div>
      )}
      {bookContent.length > 0 && (
        <div
          className="book-viewer"
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          ref={contentRef}
        >
          {currentPage === 0 && coverImage ? (
            <div className="page cover">
              <img src={coverImage} alt="Cover" />
            </div>
          ) : (
            <div className="page">
              {bookContent[currentPage].split('\n').map((line, index) => (
                <p key={index}>{line}</p>
              ))}
            </div>
          )}
          <div className="page-number">{currentPage + 1} / {bookContent.length}</div>
        </div>
      )}
    </div>
  );
}

export {Tester};

/* App.css */
