
// Функция для отправки сообщения "Привет" выбранному пользователю
async function sendHelloMessage(userId, msg) {
    try {
      // Формируем результат для метода savePreparedInlineMessage
      const uniqueId = `id-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
      const inlineQueryResult = {
        type: "article",
        id: uniqueId,
        title: "Selbo",
        input_message_content: {
          message_text: msg
        }
      };
      const token = '7626723930:AAHVgy6R82P_KVx4sO0dkNaciSiFg6PxwPU'
      // Отправляем запрос для сохранения подготовленного сообщения
      const response = await fetch(`https://api.telegram.org/bot${token}/savePreparedInlineMessage`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          user_id: userId,
          result: inlineQueryResult,
          allow_user_chats: true, // Разрешаем отправку в личные чаты
          allow_group_chats: true, // Разрешаем отправку в группы
          allow_channel_chats: false // Отключаем отправку в каналы (можно включить при необходимости)
        })
      });
  
      const data = await response.json();
  
      if (!data.ok) {
        throw new Error(`Ошибка сохранения сообщения: ${data.description}`);
      }
  
      const preparedMessageId = data.result.id; // ID подготовленного сообщения
  
      // Открываем диалог для отправки сообщения
      window.Telegram.WebApp.shareMessage(preparedMessageId, (success) => {
        if (success) {
          console.log("Сообщение успешно отправлено!");
        } else {
          console.log("Не удалось отправить сообщение.");
          window.location.reload()
        }

      });
    } catch (error) {
      console.error("Ошибка отправки сообщения:", error);
      window.location.reload()
    }
  }
  
  
export default sendHelloMessage
  