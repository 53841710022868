import { useState, useEffect } from 'react';
import '../css/AdForm.css'
import { useLocation, useNavigate } from 'react-router'
import back from '../image/closeBl.png'
import lupa from '../image/lupa.png'
import telegram from '../image/telegram.png'
import favorite from '../image/favorite.png'
import favoriteBlue from '../image/favoriteBlue.png'
import readBook from '../image/readBook.png'
import downLoad from '../image/downLoad.png'
// import user from '../image/user.png'
// import copyBl from '../image/copyBl1.png'
import share from '../image/share.png'
import { sendMassage } from "./sendTelegram";
import saveAdLocalStorage from './saveAdLocalStorage';
import saveBookLocal from './saveBookLocal';
import getSaveAds from './getSaveAds';
import deleteSaveAd from './deleteSaveAds';
import getEachBook from '../js/getEachBook'
import sendHelloMessage from './sendHelloMessage';


const AdRentMoto = (ad, userData) => {

  const [mesAd, setMesAd] = useState("")
  const [isHave, setIsHave] = useState(false)
  // const [visNot, setVisNot] = useState(false)
  const [visCopy, setVisCopy] = useState(false)
  const [visSave, setVisSave] = useState(false)

  const navigate = useNavigate();
  const location = useLocation()

  const isOpen = location.state?.isOpen ?? false;

      //Сохраненные объявления
      useEffect(() => {
          const fetchData = async () => {
            const arr = await getSaveAds("arrArts1")
          //   console.log("Вытащенные данные из локал стораге!!!", arr);
            if (arr) {
              console.log("Вытащенные данные из локал стораге в APP", arr);
              if (arr.includes(ad.ad.art)) {
                setIsHave(true)
              }
            }
          };
      
          fetchData();
          //Чтобы при измененеии заново вызывался код
          // eslint-disable-next-line
        }, [visSave]);

  // useEffect(()=>{
  //   if (ad) {
  //     setMesAd(`${ad.ad.firstName}, ${ad.ad.brand} ${ad.ad.model} доступен?`)
  //   }
  // },[ad])

  useEffect(() => {
    window.scrollTo(0, -80);
  },[])

  //Сохраняем объявление
  const saveListAds = ()=> {
    setIsHave(true)
    console.log("Смотрим и ищем ид", ad)
    const key = "arrArts1"
    saveAdLocalStorage(key, ad.ad.art)
    setVisSave(true)
    setTimeout(()=>{
      setVisSave(false)
    },1500)
  }

  //Подписка на пользователя
  const [haveUser, setHaveUser] = useState(false)

        //Сохраненные объявления
        useEffect(() => {
          const fetchData = async () => {
            const arr = await getSaveAds("arrSubUsers")
          //   console.log("Вытащенные данные из локал стораге!!!", arr);
            if (arr) {
              if (arr.includes(ad.ad.telegram)) {
                setHaveUser(true)
              }
            }
          };
      
          fetchData();
          //Чтобы при измененеии заново вызывался код
        }, [visSave]);

  const subUser = ()=> {
    setHaveUser(true)
    const key = "arrSubUsers"
    saveAdLocalStorage(key, ad.ad.telegram)
  }

  const unSubUser = () => {
    const key = "arrSubUsers"
    deleteSaveAd(key, ad.ad.telegram)
    setHaveUser(false)
  }

  //Удаляем сохраненное объявление
  const delSaveAd = () => {
    const key = "arrArts1"
    deleteSaveAd(key, ad.ad.art)
    setIsHave(false)
  }

  const handleSave = () => {
    const text = `https://t.me/Selbo_bot/Selbo?startapp=ad${ad.ad.art}`
    // navigator.clipboard.writeText(text).then(() => {
    //   setVisCopy(true)
    //   setTimeout(()=>{
    //     setVisCopy(false)
    //   },1500)
    // }).catch(err => {
    //   console.error('Ошибка при копировании:', err);
    // });
    //Пробуем делиться сообщением
      setVisCopy(true)
      setTimeout(()=>{
        setVisCopy(false)
      },2500)
    sendHelloMessage(ad.userData.id, text)
  };
  
  //Подписка на оповещения ПОТОМ
  const notific = () => {
    // setVisNot(true)
    // setTimeout(()=>{
    //   setVisNot(false)
    // },1500)
  }
  
    // const [visContact, setVisContact] = useState(false)

    //Возврат на предыдущую страницу
    const goBack = () => {
      navigate(-1);
    };
    const goSearch = () => {
      navigate('/search')
    }
    const goToRead = id => {
      const key = "nameBook"
      const key1 = ad.ad.art
      const art = ad.ad.art
      let page = 0
      let part = 0
      saveBookLocal(key, ad.ad.art, ad.ad.imageUrl[0], 0, 0)
      const linkBook = ad.ad.imageUrl[0]

      getEachBook(key1, (error, data) => {
        if (error) {
          console.error("Ошибка:", error);
          navigate(`/read${id}`, { state: { linkBook, art, page, part, from: location} })
        } else if (data) {
            page = data.page
            part = data.part
             navigate(`/read${id}`, { state: { linkBook, art, page, part, from: location} })
        } else {
          navigate(`/read${id}`, { state: { linkBook, art, page, part, from: location} })
          console.log("Данные не найдены");
        }
      })


    }

    //Отправка сообщения в бот пользователю
    const sentAsk = () => {
      // console.log("Нажали кнопочку")
      // console.log(userData)
      // console.log("Это у нас ЭД", ad)
      const url = `https://t.me/TG_marketplaceBot/TGmarketplace?startapp=ad${ad.ad.art}`
      const message = `
      <b>Сообщение от пользователя ${ad.userData.first_name}:</b>
<i>"${mesAd}"</i>

<b>Перейти в чат c: @${ad.userData.username}</b>
`
      sendMassage(ad.ad.whatsapp, message, url)
    }

    const moment = require('moment');
    require('moment/locale/ru');

    if (ad.ad.dateAd) {
    var d = moment(ad.ad.dateAd).format('LL')}
    else {d=null}


    const goToProfile = () => {
      const userName = ad.ad.telegram
      const firstName = ad.ad.firstName
      const lastName = ad.ad.lastName
      const urlImg = ad.ad.urlImg
      navigate("/profilepage", { state: { userName, firstName, lastName, urlImg, from: location } })
    }

    const goHome = () => {
      navigate('/')
    }


    return (
      <div>
          <div style={{display:"flex", flexDirection:"row", alignItems:"center", paddingTop:"10px", paddingBottom:"10px" , justifyContent:"space-between", paddingLeft:"3%", paddingRight:"3%", position:"fixed", top:"0px", zIndex:"2", width:"100%", boxSizing:"border-box"}}>
             <img onClick={isOpen ? goHome:goBack} className='imgBackForm' src={back} alt="-" />
             <img onClick={goSearch} className='imgBackForm' src={lupa} alt="-" />
        </div>
        
        <div className='eachImgContNew'>
                  <img className='eachImgNew'  src={ad.ad.imageUrl[1]} alt="Изображения не добавлены"/>
              </div>
        <p className="textUderPhoto1">{ad.ad.nameBook}</p>
        <p className="textUderPhoto2">{ad.ad.author}</p>
        <p className="textUderPhoto2">{ad.ad.genre}</p>
        <p className="textUderPhoto3">{d}</p>
          <div className="blockAlarm">
                <div className="eachAlarm">
                  <div className="imgContAlarm" onClick={()=> goToRead(ad.ad.art)}>
                     <img src={readBook} alt="1" style={{height:"22px", marginTop:"1px", marginBottom:"1px"}}/>
                  </div>
                  <p>Читать книгу</p>
                </div>
                <div className="eachAlarm">
                <div className="imgContAlarm" style={{paddingRight:"10px", paddingLeft:"10px"}}>
                  <img src={downLoad} alt="1"/>
                </div>
                  <p>Скачать</p>
                </div>
                <div className="eachAlarm">
                <div className="imgContAlarm" style={{paddingRight:"11px", paddingLeft:"9px"}} onClick={handleSave}>
                  <img src={share} alt="1" />
                  {visCopy && <p>Открываем чат</p>}
                </div>
                  <p>Поделиться</p>
                </div>
                {!isHave ?
                <div className="eachAlarm">
                <div className="imgContAlarm" onClick={saveListAds} >
                  <img src={favorite} alt="1"/>
                  {/* {visSave && <p>Сохранено</p>} */}
                  </div>
                  <p>Сохранить</p>
                </div>:
                <div className="eachAlarm">
                   <div className="imgContAlarm" onClick={delSaveAd} style={{backgroundColor:"#cce3fd"}}>
                      <img src={favoriteBlue} alt="1"/>
                    </div>
                    <p style={{color:"#1877F2"}}>Сохранить</p>
                </div>
}
          </div>
          <div className="blockDesc">
              <p style={{fontSize:"16px", fontWeight:"800", marginBottom:"5px"}}>Описание</p>
              <p style={{margin:"0", fontSize:"15px", whiteSpace: "pre-wrap"}}>{ad.ad.discription}</p>
          </div>
          <div className="blockDesc">
              <p style={{fontSize:"16px", fontWeight:"800", marginBottom:"10px"}}>Книгу опубликовал</p>
              <div className="contImgProfile">
                <img src={ad.ad.urlImg} alt="U" className="imgProfile2" onClick={goToProfile} />
                {ad.ad.firstName ? <p style={{fontWeight:"600"}}>{ad.ad.firstName} {ad.ad.lastName}</p>:<p style={{fontWeight:"600"}}>Пользователь телеграм</p>}
                {!haveUser ? 
                <div className="imgProfileBtn" onClick={subUser}>
                  <p>Подписаться</p>
                </div>:
                <div className="imgProfileBtn" onClick={unSubUser} style={{backgroundColor:"#cce3fd"}}>
                    <p style={{color:"#1877F2"}}>Подписка</p>
                </div>
                }
             </div>
          </div>
   
         {ad.ad.recense &&
         <div className="blockDesc">
          <p style={{fontSize:"16px", fontWeight:"800", marginBottom:"5px", color:"grey"}}>Отзыв {ad.ad.firstName} о книге:</p>
          <p style={{margin:"0", fontSize:"15px", whiteSpace: "pre-wrap", color:"grey"}}>{ad.ad.recense}</p>
      </div>
        }
          
          <div className="blockMes">              
              <p className="text1Block"><img src={telegram} alt=">"/> Написать комментарий</p>
              <div className="contInputBlock">
                <textarea className="inputBlock"  value={mesAd} onChange={(e)=>setMesAd(e.target.value)} type="text"/>
                <div className="btnInputBlock" >
                  <p>Отправить</p>
                </div>
               </div>
          </div>
          </div>

    )
}
export {AdRentMoto}