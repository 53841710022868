import {doc, updateDoc, getFirestore, deleteDoc} from 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import { useState, useEffect } from "react";
import '../css/NewFormAdd.css'
import data from './fields.json'
import firebaseConfig from './firebaseConfig';
import { useNavigate} from "react-router";

const RedAdd = props => {
    const navigate = useNavigate()

    //Предупреждение видимость обязательных полей
    const [visWar, setVisWar] = useState(false)


    //Файл книги
    // const [file, setFile] = useState(null);
    const [coverImage, setCoverImage] = useState(null);
      
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);

    //Объект который будем менять с помощью инпутов. По умолчанию подтягиваем поля из json в квадратных скобках указываем категорию объекта переданную в пропсах
    const [formItems, setFormItems] = useState(data.books.inputs)

    //Неактивность кнопки
    const [visButton, setVisButton] = useState(false)
    

    useEffect(() => {
        if (props.ad && props.ad.imageUrl) {
            setFormItems(props.ad);
            setCoverImage(props.ad.imageUrl[1]);
            console.log(props.ad.imageUrl[1])
        }
    }, [props.ad]); 
    


    const scrollDown = () => {
      window.scrollTo({ top: document.documentElement.scrollHeight, behavior: 'smooth' });
    }

    //Функция изменения значения
    const handleChange = (event) => {
      const { name, value } = event.target;
      setFormItems((prevState) => ({
        ...prevState,
        [name]: value
      }));
    };
    //Изменение документа
    const updateDocument = async () => {
        getTrim()
        if (
        formItems.nameBook.trim() === '' || 
        formItems.author.trim() === '' || 
        formItems.genre.trim() === '' ||
        formItems.discription.trim() === ''
        ){
          setVisWar(true)
        } 
        else {
        const formItems1 = {
            formItems: formItems
        }
        setVisButton(true)
        try {
          const documentRef = doc(db, 'selbo', props.id);
          await updateDoc(documentRef, formItems1);
          window.location.reload()
        } catch (error) {
          console.error('Ошибка при обновлении документа:', error);
          return false;
        }
    }}
    //Удаление документа
    const deleteDocument = async () => {
      try {
        const documentRef = doc(db, "selbo", props.id); 
        await deleteDoc(documentRef);
        navigate("/")
        setTimeout(()=>{
            window.location.reload();
        },50) 

    
      } catch (error) {
        console.error("Ошибка при удалении документа из базы данных", error);
      }
    };
  

//Проверка инпутов и вывод предупреждения
const [showWarning, setShowWarning] = useState({
  nameBook:false,
  author:false,
  genre:false,
  discription:false,
})

const arrFields = ["nameBook","author","genre","discription"]

const getTrim = () => {
  arrFields.map((field) => {
    if (!formItems[field] || formItems[field].trim() === '') {
      setShowWarning((prevWarnings) => ({
        ...prevWarnings,
        [field]: true
      }));
    } else {
      setShowWarning((prevWarnings) => ({
        ...prevWarnings,
        [field]: false
      }));
    }
    return null;
  });
};
    const stepBack = () => {
        navigate(-1)
    }
    const gooHome = () => {
        navigate('/')
      }

    const [getMargin, setGetMargin] = useState(false)
    

    return (
      <div className='contFormAdNew'>
            <div className="headerCreateAd"style={{marginBottom:"10px", paddingRight:"3%", paddingTop:"10px", paddingBottom:"10px"}} >
                <p className="linkCreateAd" style={{margin:"0px"}} onClick={stepBack}>Назад</p>
                <p className="textHeaderCreateAd" style={{margin:"0px"}}>Редактирование книги</p>
                <p className="linkCreateAd" style={{margin:"0px"}} onClick={gooHome}>Главная</p>
            </div>
         {coverImage && 
        <div className="contAllImg" style={{height:"35vh"}}>
         {/* <div  className="divImgAd"> */}
           <img  src={coverImage} alt={1} className='imgMapAd' /> 
        {/* </div> */}
          </div> }
            <p style={{color:"grey", fontSize:"12px"}}>Книга в формате fb2 успешно загружена.</p>
         <div style={{position:"relative"}}>
         {formItems.nameBook.trim() !== '' && <p style={{position:"absolute", fontSize:"12px", color:"gray", left:"10px", top:"-5px"}}>Название книги</p>}
          <input 
            className="inputAddNew" 
            placeholder="Название книги"
            maxLength={50}
            name="nameBook"
            type="text"
            value={formItems.nameBook}
            onChange={handleChange}
            style={formItems.nameBook.trim() !== '' ? {paddingTop:"15px", fontSize:"14px"}:{}}
          />
          </div>
          {showWarning.author && <p className="pWarning">Поле должно быть заполено</p>}
          <div style={{position:"relative"}}>
         {formItems.author.trim() !== '' && <p style={{position:"absolute", fontSize:"12px", color:"gray", left:"10px", top:"-5px"}}>Автор</p>}
          <input 
            className="inputAddNew" 
            placeholder="Автор книги"
            maxLength={30}
            name="author"
            type="text"
            value={formItems.author}
            onChange={handleChange}
            style={formItems.author.trim() !== '' ? {paddingTop:"15px", fontSize:"14px"}:{}}
          />
          </div>
          {showWarning.model && <p className="pWarning">Поле должно быть заполено</p>}
          <div style={{position:"relative"}}>
         {formItems.genre.trim() !== '' && <p style={{position:"absolute", fontSize:"12px", color:"gray", left:"10px", top:"-5px"}}>Жанр</p>}
         <input
            className="inputAddNew"
            name="genre"
            placeholder="Жанр"
            onWheel={(e) => e.target.blur()}
            value={formItems.genre}
            onChange={handleChange}
            style={formItems.genre.trim() !== '' ? { paddingTop: "15px", fontSize: "14px" } : {}}
          />

          </div>
          {showWarning.genre && <p className="pWarning">Необходимо выбрать жанр</p>}
          <div style={{position:"relative"}}>
         {formItems.discription.trim() !== '' && <p style={{position:"absolute", fontSize:"12px", color:"gray", left:"10px", top:"-5px", backgroundColor:"white", padding:"2px"}}>Описание</p>}
          <textarea
            placeholder="Описание"
            className="areaAddNew" 
            name="discription"
            maxLength={3500}
            type='text'
            value={formItems.discription}
            // style={{height:height}}
            onChange={(e)=> {handleChange(e)}}
            onFocus={()=> {scrollDown(); setGetMargin(true)}}
            onBlur={()=> setGetMargin(false)}
            style={formItems.discription.trim() !== '' ? {paddingTop:"20px", fontSize:"14px"}:{}}
          />
          </div>
          {showWarning.discription && <p className="pWarning">Поле должно быть заполено</p>}
            <p style={{color:"grey", fontSize:"12px", margin:"0", marginTop:"-10px", marginBottom:"15px"}}>Краткое описание книги. Можете оставить существующие описание, отредактировать его, или предложить свое.</p>
            
        <div style={{position:"relative"}}>
         {formItems.recense.trim() !== '' && <p style={{position:"absolute", fontSize:"12px", color:"gray", left:"10px", top:"-5px", backgroundColor:"white", padding:"2px"}}>Ваши мысли о книге</p>}
          <textarea
            placeholder="Ваши мысли о книге"
            className="areaAddNew" 
            name="recense"
            maxLength={3500}
            type='text'
            value={formItems.recense}
            // style={{height:height}}
            onChange={(e)=> {handleChange(e)}}
            onFocus={()=> {scrollDown(); setGetMargin(true)}}
            onBlur={()=> setGetMargin(false)}
            style={formItems.recense.trim() !== '' ? {paddingTop:"20px", fontSize:"14px"}:{}}
          />
          </div>
            <p style={{color:"grey", fontSize:"12px", margin:"0", marginTop:"-10px"}}>Поделитесь своими впечатлениями о книге. Напишите рецензию, расскажите о мыслях которые возникли после прочтения.</p>

          {visWar && <p className="pWarning" style={{marginTop:"15px", fontSize:"14px"}}>Проверьте правильность заполнение всех обязательных полей!</p>}
          <button className="btnAddSave" onClick={deleteDocument} disabled={visButton} style={getMargin ? {marginBottom:"20px"}:{backgroundColor:"#fa6b6b"}}>Удалить книгу</button>
          <button className="btnAddSave" onClick={updateDocument} disabled={visButton} style={getMargin ? {marginBottom:"200px"}:{}}>Сохранить изменения</button>
      
      </div>
    );
  }



export {RedAdd}
